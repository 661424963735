<template>
  <div class="px-10">
    <div class="text-h4 mb-5 primary--text">
      {{ isUpdate ? "แก้ไข" : "สร้าง" }}ข้อมูลกลุ่มลูกค้า
    </div>

    <v-row justify="center">
      <v-col cols="12" class="text-h6 primary--text">
        ข้อมูลลูกค้า
      </v-col>
      <v-col cols="5">
        <v-text-field name="name" v-model="form.name" label="ชื่อข้อมูล" outlined dense  :error-messages="$v.form.name.$error ? $error_text : ''"/>
        <!-- <v-textarea name="detail" v-model="form.detail" label="รายละเอียดการซื้อ"  outlined dense/> -->
      </v-col>
      <v-col cols="5" offset="1">
        <!-- <v-text-field name="name" v-model="form.branch_id" label="สาขา" outlined dense /> -->
        <v-select
          v-model="form.branch_id"
          :items="branchs"
          item-value="_id"
          item-text="branch_name"
          chips
          label="สาขา"
          multiple
          dense
          outlined
        ></v-select>
        <!-- <v-textarea name="note" v-model="form.note" label="หมายเหตุ" outlined dense/> -->
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" class="text-h6 primary--text">
        ส่วนลด
      </v-col>
      <v-col cols="5">
        <v-text-field  label="ฝากต่อรถยนต์/บาท" outlined dense v-model="form.car_extension_tax_fee_discount"/>
        <v-text-field  label="ฝากต่อรถจักรยานยนต์/บาท" outlined dense v-model="form.motorcycle_extension_tax_fee_discount"/>
        <v-text-field  label="ค่าจัดทำ พ.ร.บ. รถจักยานยนต์/บาท" outlined dense v-model="form.motorcycle_3rdinsurance_discount"/>
      </v-col>
      <v-col cols="5" offset="1">
        <v-text-field  label="ค่าตรวจสภาพรถยนต์ไม่เกิน 2,000 ก.ก/บาท" outlined dense v-model="form.car_inspect_2000down_discount"/>
        <v-text-field  label="ค่าตรวจสภาพรถยนต์เกิน 2,000 ก.ก/บาท" outlined dense v-model="form.car_inspect_2000up_discount"/>
        <v-text-field  label="ค่าตรวจสภาพรถจักรยานยนต์/บาท" outlined dense v-model="form.bike_inspect_discount"/>
      </v-col>
    </v-row>

    <div class="grey--text text-right mb-2">ส่วนลดค่า พ.ร.บ. ประกัน คิดเป็น % จากราคาสุทธิ</div>

    <v-data-table
      class="elevation-2"
      :headers="headers"
      :items="form.insurance_discount"
      :loading="loadingCar"
      sort-by="id"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      :loading-text="$table_loading_text"
      @page-count="pageCount = $event"
    >

      <template v-slot:[`item.3rd_insurance_id`]="{ item }">
        {{form.insurance_discount.map(function(x) {return x['3rd_insurance_id']; }).indexOf(item['3rd_insurance_id'])+1}} 
      </template>

      <template v-slot:[`item.3rd_insurance`]="{ item }">
        <v-text-field class="py-2" hide-details outlined dense v-model="item['3rd_insurance']"/>
      </template>

      <template v-slot:[`item.insurance_0`]="{ item }">
        <v-text-field class="py-2" hide-details outlined dense v-model="item.insurance_0"/>
      </template>

      <template v-slot:[`item.insurance_1`]="{ item }">
        <v-text-field class="py-2" hide-details outlined dense v-model="item.insurance_1"/>
      </template>

      <template v-slot:[`item.insurance_2`]="{ item }">
        <v-text-field class="py-2" hide-details outlined dense v-model="item.insurance_2"/>
      </template>

      <template v-slot:[`item.insurance_3`]="{ item }">
        <v-text-field class="py-2" hide-details outlined dense v-model="item.insurance_3"/>
      </template>

      <template v-slot:[`item.insurance_4`]="{ item }">
        <v-text-field class="py-2" hide-details outlined dense v-model="item.insurance_4"/>
      </template>

      <template v-slot:[`item.insurance_5`]="{ item }">
        <v-text-field class="py-2" hide-details outlined dense v-model="item.insurance_5"/>
      </template>
      <template v-slot:footer>
        <table-pagination-custom
          :page="page"
          :itemsPerPage="itemsPerPage"
          :length="pageCount"
          @change-page="onChangePage"
          @change-items-per-page="onChangeitemsPerPage"
        />
      </template>
    </v-data-table>

    <v-row>
      <v-col class="text-right py-10">
        <v-btn class="px-10 mr-15" color="grey" outlined @click="close()">ปิด</v-btn>
        <v-btn
          class="px-10"
          color="primary"
          @click="save()"
          :loading="loading"
          :disabled="loading"
        >
          บันทึกข้อมูล
        </v-btn>
      </v-col>
    </v-row>
 
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
  validations: {
    form: {
      name: { required },
    },
  },
  data: () => ({
    form: {
      name: "",
      detail: "",
      branch_id: [],
      note: "",
      car_extension_tax_fee_discount: "",
      motorcycle_extension_tax_fee_discount: "",
      motorcycle_3rdinsurance_discount: "",
      car_inspect_2000down_discount: "",
      car_inspect_2000up_discount: "",
      bike_inspect_discount: "",
      insurance_discount: [],
    },
    branchs: [],
    loading: true,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    headers: [
      {
        text: "ลำดับ",
        align: "end",
        sortable: false,
        value: "3rd_insurance_id",
        filterable: false,
      },
      { text: "พ.ร.บ. / ประกันภัย", value: "name", width: "160" },
      { text: "หนายหน้าประกัน", value: "broker", width: "160" },
      { text: "พ.ร.บ.", value: "3rd_insurance", width: "100" },
      { text: "ป.1", value: "insurance_0", width: "100" },
      { text: "ป.2", value: "insurance_1" },
      { text: "ป.2+", value: "insurance_2" },
      { text: "ป.3", value: "insurance_3" },
      { text: "ป.3+", value: "insurance_4" },
      { text: "ป.4", value: "insurance_5" },
    ],

    dialogUpdate: false,
    dialogData: null,
    loadingCar: true,
  }),

  created() {
    if (!this.isUpdate) this.getPlainData();
    this.getBranchs();
    if (this.isUpdate) this.getData();
    this.loading = false;
    this.loadingCar = false;
  },

  methods: {
    getBranchs() {
      let body = {
        token: this.$jwt.sign({}, this.$privateKey, { noTimestamp: true }),
      };
      this.$axios.post(`${this.$baseUrl}/branch/get_branch_list`, body)
        .then(async (res) => {
          this.branchs = res.result;
        });
    },
    async getData() {
      if(this.$route.params.id) {
        let body = {
          token: this.$jwt.sign({ discounted_groups_id: this.$route.params.id }, this.$privateKey, { noTimestamp: true }),
        };
        await this.$axios.post(`${this.$baseUrl}/discounted_groups/get_by_id`, body)
          .then(res=>{
            this.form = res.result;
          })
          .catch((err) => {
            this.$router.push({ name: "customer-group" });
          })
      }
    },
    onChangePage(page){
      this.page = page
      this.getData()
    },
    onChangeitemsPerPage(number){
      this.itemsPerPage = number
      this.onChangePage(1)
    },
    async getPlainData() {
      let body = {
        token: this.$jwt.sign({}, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios.post(`${this.$baseUrl}/discounted_groups/get_plain_discounted_groups`, body)
        .then(res=>{
          this.form = res.result;
        })
        .catch((err) => {
          this.$router.push({ name: "customer-group" });
        })
    },
    async save() {
      this.$v.form.$touch();
      if(this.$v.form.$invalid) return;

      this.loading = true;
      this.$alertConfirm({ title: `ยืนยันบันทึกข้อมูล ?`, text: `` }).then(
        async (result) => {
          if (result.isConfirmed) {
            let subPath = 'add_customer_group'
            if(this.isUpdate) {
              subPath = 'edit_discounted_groups'
              this.form = {discounted_groups_id : this.$route.params.id, ...this.form}
              // delete this.form._id
            } 
            let body = {
              token: this.$jwt.sign(this.form, this.$privateKey, { noTimestamp: true }),
            };
            console.log(this.form)
            await this.$axios.post(`${this.$baseUrl}/discounted_groups/${subPath}`, body)
              .then(res=>{
                this.$alertSuccess({ title : res.status });
                this.$router.push({name: 'customer-group'})
              })
              .catch((err) => {
                console.log('err',err);
                this.$alertServerError({ title : res.error_message });
              })
          }
          this.loading = false;
        }
      );
    },
    close() {
      this.$router.push({ name: "customer-group" });
    },
  },
  computed: {
    isUpdate() {
      return this.$route.params.id;
    },
  },
};
</script>
